@import '~bulma/sass/utilities/initial-variables';
@import "~bulma/sass/utilities/functions";

$primary: hsla(249, 74%, 12%, 1);
$primary-invert: findColorInvert($primary);
$link: hsla(212, 64%, 44%, 1);
$link-invert: findColorInvert($link);
$success: hsla(355, 72%, 72%, 1);
$success-invert: findColorInvert($success);
$warning: hsla(65, 100%, 50%, 1);
$warning-invert: findColorInvert($warning);

$dark: hsla(0, 0%, 46%, 1);
$grey: hsla(0, 0%, 46%, 1);
$grey-light: hsla(19, 5%, 89%, 1);

$body-line-height: 1.2;
$section-padding: 1.75rem 1rem;

$radius-rounded: 16px;

$widescreen-enabled: false;
$fullhd-enabled: false;

@import '~bulma/bulma';

small {
  font-size: 0.75rem;
}

.buttons:not(.has-addons) {
  .button {
    font-size: 14px;
    margin-bottom: 0.7rem;
    &:not(:last-child):not(.is-fullwidth) {
      margin-right: 0.7rem;
    }
    &.is-small {
      font-size: 0.75rem;
    }
  }
}

.image {
  img.is-rounded {
    border-radius: 290486px;
  }
}
